import React from "react";
import classNames from "classnames";

export default function IconManage({color = 'gray', size = '1em', className = ''}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             className={classNames(className)}
             style={{width: size, height: `calc((11.9748/11.4898 * ${size})`}}
             viewBox="0 0 1141.53 1189.71">
            <g>
                <path fill={color}
                      d="M570.76 0l570.76 325.08 -236.84 134.89 236.84 134.89 -236.84 134.89 236.84 134.9 -570.76 325.07 -570.76 -325.07 236.84 -134.9 -236.84 -134.89 236.84 -134.89 -236.84 -134.89 570.76 -325.08zm260.51 771.56l-260.51 148.37 -260.51 -148.37 -163.42 93.08 423.93 241.45 423.93 -241.45 -163.42 -93.08zm0 -269.79l-260.51 148.37 -260.51 -148.37 -163.42 93.08 423.93 241.45 423.93 -241.45 -163.42 -93.08zm-260.51 -418.16l423.93 241.46 -423.93 241.45 -423.93 -241.45 423.93 -241.46z"/>
            </g>
        </svg>)
}